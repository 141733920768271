export enum PermissionsEnum {
  ViewCampaigns = 'view:campaigns',
  ViewUsers = 'view:users',
  ViewMessages = 'view:messages',
  ViewSegments = 'view:segments',
  ViewBarcodes = 'view:generatedcodes',
  ViewReports = 'view:reports',
  ViewTenantsettings = 'view:tenantsettings',
  ViewEmailTemplates = 'view:templates',
  EditCampaigns = 'edit:campaigns',
  EditUsers = 'edit:users',
  EditMessages = 'edit:messages',
  EditSegments = 'edit:segments',
  EditBarcodes = 'edit:generatedcodes',
  EditTenantsettings = 'edit:tenantsettings',
  EditEmailTemplates = 'edit:templates',
}
