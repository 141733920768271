import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Observable, catchError, finalize, map, of, switchMap, tap, timer } from 'rxjs';

import { IApiResponse, IAsyncValidationResponse, IBasicApiResponse } from '@libs/shared/interfaces';
import { IPhone } from '@libs/shared/ui-components/input-phone/phone-input.component';

export class ValidationHelper {
  public static createTextFieldAsyncValidator(
    validationObservable: (
      value: string
    ) => Observable<
      IApiResponse<IAsyncValidationResponse> | IBasicApiResponse<IAsyncValidationResponse>
    >,
    initialValue?: string
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const trimmedValue =
        control?.value == null ? control?.value : (control?.value as string).trim();

      if (!trimmedValue) {
        return of(null);
      }

      if (initialValue != null && trimmedValue === initialValue) {
        return of(null);
      }

      return timer(1000).pipe(
        tap(() => ((control as any).isLoading = true)),
        switchMap(
          () =>
            validationObservable(trimmedValue)?.pipe(
              map(response =>
                !response.data.isValid
                  ? <ValidationErrors>{ customError: { message: response.data.message } }
                  : null
              ),
              catchError(response => {
                const message = response.error?.errors?.[0] || 'Unknown Error';

                return of(<ValidationErrors>{ customError: { message } });
              }),
              finalize(() => ((control as any).isLoading = false))
            )
        )
      );
    };
  }

  public static createPhoneNumberAsyncValidator(
    validationObservable: (
      value: IPhone
    ) => Observable<
      IApiResponse<IAsyncValidationResponse> | IBasicApiResponse<IAsyncValidationResponse>
    >,
    initialValue?: IPhone
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control?.value) {
        return of(null);
      }

      if (initialValue != null && JSON.stringify(initialValue) === JSON.stringify(control.value)) {
        return of(null);
      }

      return timer(1000).pipe(
        tap(() => ((control as any).isLoading = true)),
        switchMap(() =>
          validationObservable(control?.value).pipe(
            map(response =>
              !response.data.isValid
                ? <ValidationErrors>{ customError: { message: response.data.message } }
                : null
            ),
            catchError(() => of({ customError: true })),
            finalize(() => ((control as any).isLoading = false))
          )
        )
      );
    };
  }

  public static endDateValidator(): ValidatorFn {
    return (endDateControl: AbstractControl): ValidationErrors | null => {
      if (!endDateControl.parent) {
        return null;
      }

      const startDateControl = endDateControl.parent.get('startDate') as FormControl;
      const startDate = startDateControl?.value;
      const endDate = endDateControl.value;

      if (startDate && endDate) {
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);

        if (startDateObj > endDateObj) {
          return {
            customError: {
              message: $localize`End date must be equal to or later than start date.`,
            },
          };
        }
      }

      return null;
    };
  }

  public static futureDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!(control && control.value)) {
        return null;
      }

      const controlDate = new Date(control.value);
      const controlDateUTC = Date.UTC(
        controlDate.getUTCFullYear(),
        controlDate.getUTCMonth(),
        controlDate.getUTCDate()
      );

      const now = new Date();
      const localMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const currentDateUTC = Date.UTC(
        localMidnight.getFullYear(),
        localMidnight.getMonth(),
        localMidnight.getDate()
      );

      return controlDateUTC > currentDateUTC
        ? {
            customError: {
              message: $localize`Selected date can't be later than today.`,
            },
          }
        : null;
    };
  }

  public static periodLess366Validator(): ValidatorFn {
    return (endDateControl: AbstractControl): ValidationErrors | null => {
      if (!endDateControl.parent) {
        return null;
      }

      const startDateControl = endDateControl.parent.get('startDate') as FormControl;
      const startDate = startDateControl?.value;
      const endDate = endDateControl.value;

      if (startDate && endDate) {
        const startDateObj = new Date(startDate);
        startDateObj.setDate(startDateObj.getDate() + 364);
        const endDateObj = new Date(endDate);

        if (startDateObj < endDateObj) {
          return {
            customError: {
              message: $localize`The period must be less than 366 days.`,
            },
          };
        }
      }

      return null;
    };
  }

  public static guidValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
      const valid = regex.test(control.value);

      return valid
        ? null
        : {
            customError: {
              message: $localize`Invalid Guid.`,
            },
          };
    };
  }

  public static patternValidator(pattern: RegExp, errorMessage?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const message = errorMessage || $localize`Invalid value`;
      const valueMatceshPattern = pattern.test(control.value);

      return valueMatceshPattern ? null : { customError: { message } };
    };
  }
}
